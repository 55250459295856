import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { media, Row, Col } from 'styled-bootstrap-grid';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { rem, lh, flatButtonStyle } from '../../../../styles/utils';
import { FormInput, Spinner } from '../../../common';
import { updatePassword } from '../../../../utils/auth';
import { useAuthDispatch } from '../../../../hooks/auth-context';

const Container = styled.div`
  font-family: var(--font-body);
  color: var(--font-primary-color);
`;

const Form = styled.form``;

const Headline = styled.h4`
  font-family: var(--font-header);
  font-size: ${rem(24)};
  line-height: ${lh(34, 24)};
  font-weight: 400;
  margin-bottom: 14px;
  margin-top: 0;

  ${media.lg`
    border-bottom: 1px solid rgba(0,0,0,0.2);
    padding-bottom: 10px;
  `}
`;

const PasswordTip = styled.div`
  color: var(--cornflower-blue);
  font-size: ${rem(12)};
  line-height: ${lh(14, 12)};

  ${media.md`
    font-size: ${rem(14)};
    line-height: ${lh(21, 16)};
  `}
`;

const Button = styled.button`
  ${flatButtonStyle()}
  margin-top: 20px;

  ${media.lg`
    margin-top: 30px;
  `}
`;

type FormData = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

type Props = {
  className?: string;
};

const UpdatePassword: React.FC<Props> = ({ className }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAuthDispatch();
  const { register, handleSubmit, errors, watch } = useForm<FormData>();
  const newPasswordInputRef = useRef({});
  newPasswordInputRef.current = watch('newPassword', '');

  const onSubmit = async (data: FormData) => {
    const { newPassword, currentPassword } = data;

    setIsLoading(true);
    const error = await updatePassword(dispatch, currentPassword, newPassword);
    setIsLoading(false);

    if (error) {
      toast.error(error);
      return;
    }

    toast.success('Your password has been successfully updated.');
  };

  return (
    <Container className={className}>
      <Headline>Password</Headline>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={12} md={6}>
            <FormInput
              label="Current password"
              error={errors.currentPassword}
              inputRef={register({
                required: 'Current password is required',
              })}
              inputProps={{
                name: 'currentPassword',
                type: 'password',
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <FormInput
              label="New password"
              error={errors.newPassword}
              inputRef={register({
                required: 'New password is required',
              })}
              inputProps={{
                name: 'newPassword',
                type: 'password',
              }}
            />
          </Col>
          <Col xs={12} md={6}>
            <FormInput
              label="Confirm new password"
              error={errors.confirmPassword}
              inputRef={register({
                required: 'Confirm new password is required',
                validate: value =>
                  value === newPasswordInputRef.current ||
                  'The passwords do not match',
              })}
              inputProps={{
                name: 'confirmPassword',
                type: 'password',
              }}
            />
          </Col>
        </Row>
        <PasswordTip>
          <b>Password strength:</b>
          <br />
          Use at least 8 characters. Don’t use a password from another site, or
          something too obvious like your pet’s name.
        </PasswordTip>
        <Button type="submit">Change password</Button>
      </Form>
      <Spinner isLoading={isLoading} />
    </Container>
  );
};

export default UpdatePassword;
